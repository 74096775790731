import { useAccountService } from '~/providers/account';
import { UserDTO, PublicProfileDTO } from '~/restAPI/data-contracts';
import { AnalyticsEvents } from '~/types/analytics';

export function useFollowUser(user?: PublicProfileDTO | UserDTO) {
  const followLoading = ref(false);
  const isFollowRes = ref(!!user?.isFollowed);
  const { isAuth, openAuthDialog } = useAccountStore();
  const { $addEvent } = useNuxtApp();
  const { t } = useI18n();
  const { openPhoneVerifyDialog, phoneVerifyWebPopup } = usePhoneVerification();

  async function follow() {
    const id = user?.id;

    if (!id) return;

    followLoading.value = true;

    if (!isAuth.value) {
      openAuthDialog({ chapter: 'subscribe_user' });
      followLoading.value = false;
      return;
    }

    if (!isFollowRes.value && phoneVerifyWebPopup.value?.productSceneSubscribe) {
      openPhoneVerifyDialog({
        chapter: 'productSceneSubscribe',
        phoneText: t('composables.useFollowUser.phoneText'),
      });
    }

    const { toggleFollowing } = useAccountService();
    const { data } = await useAsyncData(() => toggleFollowing(id));

    $addEvent(isFollowRes.value ? AnalyticsEvents.UserUnfollow : AnalyticsEvents.UserFollow, {
      userId: id,
    });

    isFollowRes.value = !!data.value;
    followLoading.value = false;
  }

  const isFollowed = computed(() => isFollowRes.value);
  const followedText = computed<string>(() => (isFollowed.value ? t('composables.useFollowUser.followed') : t('composables.useFollowUser.follow')));

  return {
    followLoading,
    isFollowed,
    follow,
    followedText,
  };
}
